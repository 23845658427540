import { createRouter, createWebHistory } from 'vue-router';

import HomeVue from './views/HomeVue';
import ProfileVue from './views/ProfileVue';
import AttackVue from './views/AttackVue';
import MessageVue from './views/MessageVue';
import OnlineVue from './views/OnlineVue';
import MessageBoardVue from './views/MessageBoardVue';
import PurchaseVue from './views/PurchaseVue';
import ProduceVue from './views/ProduceVue';
import SpecialVue from './views/SpecialVue';
import ScrollVue from './views/ScrollVue';
import TypeAheadVue from './views/TypeAheadCustom';
import PaslodeVue from './views/PaslodeVue';
import ScoutVue from './views/ScoutVue';
import RanksCollection from './components/RanksCollection';
import Discipline from './components/Discipline';
import NotFound from './components/NotFound';



const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes: [
	{
			path: '/',
			component: HomeVue
		},
		{
			path: '/profile/:id',
			name: 'Profile',
			component: ProfileVue
		},
		{
			path: '/scout',
			component: ScoutVue
		},
		{
			path: '/online',
			component: OnlineVue
		},
		{
			path: '/ranks',
			component: RanksCollection
		},
		{
			path: '/special',
			component: SpecialVue
		},
		{
			path: '/scroll',
			component: ScrollVue
		},
		{
			path: '/type-ahead',
			component: TypeAheadVue
		},
		{
			path: '/paslode',
			component: PaslodeVue
		},
		{
			path: '/attack/:id',
			name: 'Attack',
			component: AttackVue
		},
		{
			path: '/message/:id',
			name: 'Message',
			component: MessageVue
		},
		{
			path: '/message-board',
			component: MessageBoardVue
		},
		{
			path: '/purchase',
			component: PurchaseVue
		},
		{
			path: '/produce',
			component: ProduceVue
		},
		{
			path: '/discipline',
			component: Discipline
		},
		{
			path: '/:pathMatch(.*)', // not working
			// path: '/opp', // working
			component: NotFound
		}
	] 
});



export default router;