<template>
  <div class="test-template template">
    <div class="window">
    </div>
    <div>
      <h2>debug panel</h2>
      <div class="new-user box">
        <h3>Current User</h3>
        <label for="new-user">
          <input v-model="newUser" type="number" id="new-user" name="new-user" min="1" max="60">
        </label>
        <Button @btn-click="updateUserId(newUser)" text="update" />
        <div class="output">
          newUser: {{newUser}} <br>
          <span class="highlight">currentUser: {{currentUser}}</span>
        </div>
      </div>
      <div class="context-user box">
        <h3>Context User</h3>
        <label for="context-user">
          <input v-model="newContextUser" type="number" id="context-user" name="context-user" min="1" max="60">
        </label>
        <Button @btn-click="updateContextUserId(newContextUser)" text="update" />
        <div class="output">
          newContextUser: {{newContextUser}} <br>
          <span class="highlight">currentContextUser: {{currentContextUser}}</span>
        </div>
      </div>
    </div>
    
  </div>
</template>


<script>
import Button from './Button';

export default {
  name: 'Test Widget',
  props: {
  },
  data (){
    return {
      visible: true,
      // userData
      // currentUser: this.userData[0]
      newUser: 1,
      currentUser: 1,
      newContextUser: 2,
      currentContextUser: 2
    }
  },
  components: {
    Button
  },
  methods: {
    updateUserId(id){
      this.newUser = this.currentUser = id;
      this.$store.commit('updateUserId', this.newUser);
    },
    updateContextUserId(id){
      this.newContextUser = this.currentContextUser = id;

      // update url params
      // here 



      this.$store.commit('updateContextUserId', this.newContextUser);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.window{
  position: absolute;
  background: rgba(0,200,0,.2);
  width: 100%;
  left: 0;
  top: -94px;
  height: 92px;
  display: none;
}
button{
  padding: 4px 10px;
}
.test-template{
  // display: none;
  opacity: .85;
  position: fixed;
  z-index: 9999;
  left: 0;
  // top: 180px;
  bottom: 315px;

  color: #f7f7f7;
  padding: 10px;
  background: #454545;
  min-height: 200px;
  min-width: 150px;
  border: solid 1px;
  h2{
    color: #dfdfdf;
    font-size: 18px;
    border-bottom: solid 1px;
    padding-bottom: 5px;
    margin-bottom: 15px;
  }
  h3{
    font-size: 16px;
  }
}
.box{
  padding: 10px;
  background-color: rgba(255,255,255,.1);
  margin-bottom: 15px;
  &:last-of-type{
    margin-bottom: 0;
  }
  .output{
    margin-top: 10px;
  }
}
.highlight{
  background-color: rgba(0,0,0,.7);
  padding: 3px;
}

</style>