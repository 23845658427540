<template>
  <div class="page page-online">
    <h1>who is online</h1>
    <div class="online-list-wrapper">
      <table class="online-list table table-striped">
        <thead>
          <tr>
            <th>rank</th>
            <th>pimp</th>
            <th>currently</th>
            <th>idle time</th>
            <th>worth</th>
            <th>msg</th>
            <th>atk</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="record in getFirstFive" :key="record.id">
            <td class="record-rank">
              {{record.id}}
            </td>
            <td class="record-name">
              {{record.name}}
            </td>
            <td class="record-state">
              viewing online pimps [state]
            </td>
            <td class="record-idle">
              Idle 55 seconds
            </td>
            <td class="record-networth">
              $abunch
            </td>
            <td class="record-contact">
              <!-- <img src="https://place-hold.it/32x32" /> -->
              <!-- <a href="#" class="contact">
                <img class="ranks-icon icon-mail" src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_184197.png&f=1&nofb=1&ipt=54abac7c470c1357230ae7b32902678c2b1db8ed2cd00faf94247bb1a46c6984&ipo=images" />
              </a> -->
              <router-link :to="'/message/' + record.id" class="img-restriction"><img class="ranks-icon icon-mail" src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_184197.png&f=1&nofb=1&ipt=54abac7c470c1357230ae7b32902678c2b1db8ed2cd00faf94247bb1a46c6984&ipo=images" /></router-link>
            </td>
            <td class="record-is-attackable">
              <!-- <img src="https://place-hold.it/32x32" /> -->
              <!-- <a href="#" class="contact">
                <img class="ranks-icon icon-attack" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.8lR-ibRWAIbWZIOkxAIfqAHaER%26pid%3DApi&amp;f=1&amp;ipt=6983ae7fc377d625a4a3876774b268e51d4f674f8da0cab8e3a9fb3c87f37fa0&amp;ipo=images" />
              </a> -->
              <router-link :to="'/attack/' + record.id" class="img-restriction"><img :class="record.attackActive" class="ranks-icon icon-attack" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.8lR-ibRWAIbWZIOkxAIfqAHaER%26pid%3DApi&amp;f=1&amp;ipt=6983ae7fc377d625a4a3876774b268e51d4f674f8da0cab8e3a9fb3c87f37fa0&amp;ipo=images" /></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>




<script>


export default {
  name: 'page-online',
  data (){
    return {
    }
  },
  created() {
  },
  computed: {
    getContrivedOnlineList() {
      let datadump = this.$store.state.userData;

      let randoms = datadump.filter(function(item){
        let randomNum = Math.floor(Math.random() * 14);
        if(randomNum < 5){
          return item;
        }
      });

      console.log('randoms: ', randoms);


      console.log('datadump: ', datadump);
      // return 'x minus 2'
      return randoms
    },
    getFirstFive() {
      let datadump = this.$store.state.userData;

      let randoms = datadump.filter(function(item, index){
        // let randomNum = Math.floor(Math.random() * 14);
        console.log('index: ', index);
        if(index < 5){
          if(index === 2){
            item.attackActive = false;
            console.log("item: ", item, item.name);
            return item;
          }
          return item;
        }
      });

      console.log('randoms: ', randoms);


      console.log('datadump: ', datadump);
      // return 'x minus 2'
      return randoms
    }
  },
  components: {
  },
  methods: {
  },
}
</script>

<style>
.page-online{
  margin-top: 120px;
}
.icon-disabled{
  opacity: .25;
}
.ranks-icon{
  max-width: 32px;
}
.icon-mail{
  max-width: 24px;
}
.icon-attack{
  max-width: 36px;
}
</style>