<!-- <template>
  <div class="hero">
    <div class="hero-content">

      <h1>Search results for <span class="query-label">{{searchedQuery}}</h1>

      <div class="query-input">
        <div class="form-group">
          <input id="app-search-input" type="text" autocomplete="off" placeholder="Search term" class="form-control filter-search" v-model="searchQuery" @input="readyNewSearch" @focus="isDropdownOpen == true" @blur="onBlur"> 
          <label for="app-search-input" class="sr-only">Search</label>
          <button @click="searchNew" type="button">Search</button>
        </div>
      </div>

      <div class="type-ahead-dropdown" v-if="(typeAheadItems.Items && (typeAheadItems.Items.length > 0) && isDropdownOpen)">
        <ul>
          <li v-for="item in typeAheadItems.Items" class="type-ahead-item" @mousedown.prevent="setQuery(item)">
            {{item.Title}} <span class="item-cat" v-if="item.Type">{{item.Type}}</span>
          </li>
        </ul>
      </div>

    </div>
  </div>
</template> 
{
  Title: '',
  Type: '',
  Url: '',
}
-->

<!-- <template>
  <div :class="typeaheadState">
    <div class="typeahead__toggle" ref="toggle" @mousedown.prevent="toggle">
      <input type="text" class="typeahead__search" ref="search"
        v-model="search"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.esc="onEscape"
        @keydown.down="onDownKey"
        @keydown.up="onUpKey"
        @keydown.enter="onEnterKey"
      >
      <span class="typeahead__text" ref="text">{{displayText}}</span>
    </div>
    <ul class="typeahead__list" v-if="open">
      <li class="typeahead__item" v-for="(option,index) in options" :key="index">
        <a class="typeahead__link" @mousedown.prevent="select(option)" :class="[selectIndex === index ? 'typeahead__active' : '']">
          {{option.text}}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: null
    }
  },

  data(){
    return {
      open: false,
      selectIndex: 0,
      displayText: '',
      search: ''
    }
  },

  computed: {
    typeaheadState() {
      return this.open ? 'typeahead typeahead__open' : 'typeahead';
    },
    // filteredOptions() {
    //   const exp = new RegExp(this.search, 'gi');

    //   console.log('search');
    //   if(this.search == ''){
    //     console.log('return empty');
    //     return [];
    //   }

    //   console.log('exp: ', exp.test());

    //   return this.options.filter((option) => {
    //     return exp.test(option.id) || exp.test(option.text);
    //   })
    // }
  },

  methods: {

    select(option) {
      this.displayText = option.text;
      // this.$emit('input', (option.id));
      this.$refs.search.blur();
    },

    toggle (e){
      if(e.target === this.$refs.toggle ||
          e.target === this.$refs.search ||
          e.target === this.$refs.text) {

        if(this.open) {
          if(e.target !== this.$refs.search &&
              e.target !== this.$refs.text){

            this.$refs.search.blur();
          }
        }else{
          
          this.$refs.search.focus();
        }
      }
    },
    onFocus(){
      // this.displayText = '';
      // this.selectIndex = 0;
      this.open = true;
    },
    onBlur(){
      this.search = ''; /// here
      this.selectIndex = 0;
      this.open = false;
    },
    onEscape(){
      this.$refs.search.blur();
    },
    onDownKey(){
      if(this.options.length -1 > this.selectIndex){
        this.selectIndex++
      }
    },
    onUpKey(){
      if(this.selectIndex > 0){
        this.selectIndex--
      }
    },
    onEnterKey(){
      const option = this.options[this.selectIndex];

      if(option){
        this.select(option);
      }

      // work here
      // test if option == search : if u didn't select from dropdown, api call w/ typed text : otherwise, api call w/ option selected from dropdown
      // emit to parent to call api
      // make emit to parent function to do that work in a function : api call emit params to parent to call api 


      console.log(`searching: ... ${this.search}`);
      console.log(`searchingOption: ... ${this.option}`);
    }
  }  
}
</script>
 -->


<template>
    <div :class="typeaheadState">
        <div class="typeahead__toggle" ref="toggle" @mousedown.prevent="toggle">
            <input type="text" class="typeahead__search" ref="search"
                v-model="search"
                @focus="onFocus"
                @blur="onBlur"
                @keydown.esc="onEscape"
                @keydown.down="onDownKey"
                @keydown.up="onUpKey"
                @keydown.enter="onEnterKey"
                @input="onInput"
                >
            <span class="typeahead__text" ref="text">{{displayText}}</span>
            <button class="search-btn" ref="btn" type="button" @click="onSearch">Search</button>
        </div>
        <ul class="typeahead__list" ref="list" v-if="open">
            <li class="typeahead__item" v-for="(option, index) in options" :key="index">
                <a class="typeahead__link" @mousedown.prevent="select(option)"
                    :class="[selectIndex === index ? 'typeahead__active':'']">

                    {{option.text}}

                </a>
            </li>
        </ul>
    </div>
</template>
<script type="text/javascript">
    export default {
        props: {
            options: {
                type: Array,
                default() {
                    return []
                }
            },
            value: {
                type: [String, Number],
                default: null
            }
        },
        data() {
            return {
                open: false,
                selectIndex: -1,
                displayText: '',
                search: ''
            }
        },
        computed: {
            typeaheadState() {
                return this.open ? 'typeahead typeahead__open' : 'typeahead'
            },
            // filteredOptions() {
            //     const exp = new RegExp(this.search, 'i')
            //     return this.options.filter((option) => {
            //         return ( exp.test(option.id) || exp.test(option.text))
            //     })
            // }
        },
        methods: {
            onInput() {
              console.log('on input');

              if(this.search.length > 0){
                this.$emit('update-search', this.search);  
              }else{
                this.$emit('update-search', '');
              }
              
            },
            onDownKey() {
                if(this.options.length -1 > this.selectIndex) {
                    this.selectIndex++
                }
            },
            onUpKey() {
                if(this.selectIndex > -1) {
                    this.selectIndex--
                }
            },
            onEnterKey() {
                const option = this.options[this.selectIndex]

                if(option) {
                    this.select(option)
                }
                this.$emit('update-search', this.search);
                this.displayText = this.search;
                this.open = false;
            },
            onSearch() {
              this.displayText = this.search;
              this.$emit('update-search', this.search);
            },
            select(option) {
                this.search = option.text
                this.displayText = option.text
                this.$emit('update-search', this.search);
                this.$refs.search.blur()
            },
            toggle(e) {
                if(e.target === this.$refs.toggle ||
                    e.target === this.$refs.search ||
                    e.target === this.$refs.text) {

                    if(this.open) {
                        if(e.target !== this.$refs.search &&
                            e.target !== this.$refs.text) {
                            this.$refs.search.blur()
                        }
                    } else {
                        this.$refs.search.focus()
                    }
                }
            },
            onFocus() {
                this.open = true
            },
            onBlur() {
                // this.search = ''
                // this.$emit('update-search', '')
                // this.selectIndex = 0
                this.displayText = this.search
                this.open = false
            },
            onEscape() {
                this.$refs.search.blur()
            },
        }
    }
</script>




<style>
*{
  box-sizing: border-box;
}
.search-btn{
  position: absolute;
  right: 0;
}
.typeahead{
  text-align: left;
  max-width: 400px;
  margin: auto;
  border-radius: 3px;
  border: solid 1px #ccc;
  position: relative;
  z-index: 1;
  width: 100%;
  font-size: 14px;
}
.typeahead__open{
  border: solid 1px green;
}
.typeahead__open .typeahead__text{
  color: #999;
  opacity: .1;
}
.typeahead__toggle{
  position: relative;
  z-index: 1;
  width: 100%;
}
.typeahead__search{
  position: absolute;
  top: -3px;
  left: 0;
  line-height: 1em;
  font-size: 1em;
  padding: 10px;
  width: 100%;
  display: block;
  cursor: text;
  background: transparent;
  border: none;
  outline: none;
  z-index: 2;
  color: black;
}
.typeahead__text{
  min-height: 36px;
  font-size: 1em;
  line-height: 1em;
  padding: 10px;
  display: inline-block;
  position: relative;
  z-index: 3;
  color: black;
}
.typeahead__list{
  padding: 0;
  margin: 0;
}
.typeahead__item{
  display: block;
  border-top: solid 1px #f4f4f4;
}
.typeahead__link{ 
  text-decoration: none;
  color: black;
  display: block;
  padding: 10px;
  line-height: 1em;
  font-size: 1em;
  cursor: pointer;
}
.typeahead__link:hover{
  color: black;
}
.typeahead__active{
  background: green;
  color: white;
}
.typeahead__active.typeahead__link:hover{
  color: white;
}

</style>

