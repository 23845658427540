import { createStore } from 'vuex';
import userData from '../data/userData';
import ranksData from '../data/ranksData';

export default createStore({
  state: {
    postOffice: [
      {
        mailUserId: 3,
        mailUserMsgs: [

        ]
      }
    ],
    messages: [],
    messageBoard: [],
    test: {
      first: 'firstname',
      last: 'lastname'
    },
    currentUserId: 1,
    currentContextUserId: 2,
    userData: userData,
    ranksData: ranksData
  },
  mutations: {

    addMessage(state, msg){
      console.log('msg.toPlayer.name: ', msg.toPlayer.name);
      console.log('msg: ', msg);
      state.messages.push(msg);
      console.log('new msgs: ', state.messages);
    },

    addMessageBoardMessage(state, msg){
      state.messageBoard.push(msg);
      console.log('new msgs-board-msg: ', state.messageBoard);
    },

    updateUsersData(state, data){   
      state.userData[data.id].hoe += data.hoe;
      state.userData[data.id].thug += data.thug;
      state.userData[data.id].cashOnHand += data.cashOnHand;
      state.userData[data.id].turns += data.turns;
    },
    updateRanksData(state, data){
      state.ranksData[data.id].hoe += data.hoe;
      state.ranksData[data.id].thug += data.thug;
    },

    updateUserId(state, data){
      console.log('updateUserId(): ', data);
      state.currentUserId = data; // need to consolidate currentUser and currentUserId ****************** obviously ****************** // bring that logic together here prolly 

    },

    updateContextUserId(state, data){
      console.log('updateContextUserId(): ', data);
      state.currentContextUserId = data; // need to consolidate currentUser and currentUserId ****************** obviously ****************** // bring that logic together here prolly 

    },

    updateProduceDrugsCrack(state, data){
      console.log('updateProduceDrugsCrack(): ', data);
      let {crack} = data;
      state.userData[state.currentUserId-1].crack += crack;
    },

    updateProduceDrugsWeed(state, data){
      console.log('updateProduceDrugsWeed(): ', data);
      let {weed} = data;
      state.userData[state.currentUserId-1].weed += weed;
    },

    updatePurchaseDrugs(state, data){

      let {condoms, crack, meds, weed, cashOnHand} = data;

      state.userData[state.currentUserId-1].condoms += condoms;
      state.userData[state.currentUserId-1].crack += crack;
      state.userData[state.currentUserId-1].weed += weed;
      state.userData[state.currentUserId-1].meds += meds;
      state.userData[state.currentUserId-1].cashOnHand += cashOnHand;
      // state.userData[data.id].cashOnHand += data.cashOnHand;
    },

    updatePurchaseRides(state, data){

      let {bikes, cutlas, hummer} = data;

      state.userData[state.currentUserId-1].rides.bikes += bikes;
      state.userData[state.currentUserId-1].rides.cutlas += cutlas;
      state.userData[state.currentUserId-1].rides.hummer += hummer;
    },

    updatePurchaseGuns(state, data){

      let {thug, glock, shotgun, ak} = data;

      state.userData[state.currentUserId-1].thug += thug;
      state.userData[state.currentUserId-1].guns.glock += glock;
      state.userData[state.currentUserId-1].guns.shotgun += shotgun;
      state.userData[state.currentUserId-1].guns.ak += ak;
    },

  },

  getters: {

    getUserMessageBox(state) {
      let msgs = state.messages;
      let newMessages = msgs.filter(function(item){
        return (item.toPlayerId == state.currentUserId || item.fromPlayerId == state.currentUserId);
        // return (item.toPlayerId === state.currentUserId || item.fromPlayerId === state.currentUserId); 
      });
      console.log('xxxxxxxxx: ', newMessages);
      return newMessages;
    },
    getMessageBoard(state){
      return state.messageBoard;
    },

    getCurrentUserId(state){
      console.log('getting current user ID', state.currentUserId);
      return state.currentUserId;
    },
    getCurrentUser(state){
      let field = state.userData;
      let usr = field.filter(function(item){
        return item.id == state.currentUserId;
      });
      console.log('user and all the data: ', usr);
      return usr;
    },

    getContextUser(state){
      let field = state.userData;
      let contextUser = field.filter(function(item){
        return item.id == state.currentContextUserId;
      });
      console.log('context user and all the data: ', contextUser);
      return contextUser;
    },

    getRanksData(state){
      let ranksData = state.userData.map(function(item){
        let record = {
          id: item.id,
          name: item.name,
          hoe: item.hoe,
          thug: item.thug,
          // rides: item.rides.bikes,
          rides: (item.rides.bikes + item.rides.cutlas + item.rides.hummer),
          // networth: 0,
          networth: ((item.hoe * 2000) + (item.thug * 500)),
          isOnline: item.meta.isOnline,
          isAttackable: false
        }
        return record;
      });

      console.log('ranks Data from mapping: ', ranksData);

      return ranksData;
    },

    getAction(){
      console.log('getAction gettter');
    }

  },
  actions: {
    doAction () {
      console.log('doing action');
      // this.getAction();
    }
  },
  modules: {
  }
})
