<template>
	<button :style="{background: color}" @click="onClick()">{{this.text}}</button>
</template>

<script>
	
export default {
	name: 'Button',
	props: ['text', 'color'],
	methods: {
		onClick(){
			this.$emit('btn-click', event);
		}
	}
}

</script>

<style scoped lang="scss">
button{
	padding: 5px 15px;
}
</style>