<template>
	<footer>
		<StatusBar :user="getCurrentUser[0]" />
		<!-- {{timer}} -->
		<div class="bottom-nav-bar">
			<nav class="bottom-main-nav">
				<ul>
          <li><router-link to="/message-board/">Messge Board</router-link></li>
					<li><a href="#">Crew Board</a></li>
					<li><a href="#">Recruiting Board</a></li>
					<li><a href="#">Support</a></li>
				</ul>
			</nav>
			<nav class="bottom-sub-nav">
				<ul>
					<li><a href="#">my profile</a></li>
					<li><a href="#">pimp awards</a></li>
					<li><a href="#">game guide</a></li>
					<li><a href="#">game rules</a></li>
					<li><a href="#">options</a></li>
				</ul>
			</nav>
    </div>
    <div class="debug">
      <p>current user</p>
      <!-- <p class="debug-data">name: {{name}}, cash: {{cash}}, hoe: {{hoe}}, condoms: {{condoms}}, crack: {{crack}}, weed: {{weed}}</p> -->
      <p class="debug-data">{{getCurrentUser[0]}}</p>
      <p>context user</p>
      <!-- <p class="debug-data">name: {{contextname}}, cash: {{contextcash}}, hoe: {{contexthoe}}, condoms: {{contextcondoms}}, crack: {{contextcrack}}, weed: {{contextweed}}</p> -->
      <p class="debug-data">{{getContextUser[0]}}</p>
      
    </div>
	</footer>
</template>



<script>
import StatusBar from './StatusBar.vue';
// import userData from '../data/userData';

export default {
	name: 'Footer',
	props: {
	},
	data() {
		return {
		}
	},
	components: {
		StatusBar
	},

  computed: {
    getCurrentUser () {
      console.log('here: ', this.$store.getters.getCurrentUser);
      return this.$store.getters.getCurrentUser;
    },
    getContextUser () {
      return this.$store.getters.getContextUser;
    }
  },

	methods: {
		doStuff() {
			return 'nothing'
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
footer{
	width: 100%;
	background-color: #dfdfdf;
	border-top: solid 2px #777;
	position: fixed;
	bottom: 0;
}
ul,li{
	padding: 0;
	margin: 0;
	list-style-type: none;
}
li{
	position: relative;
	display: inline-block;
	margin-right: 30px;
	&:after{
		content: '\00BA';
		position: absolute;
		top: 6px;
		right: -18px;
	}
	&:last-of-type{
		margin-right: 0;
		&:after{
			content: '';
		}
	}
	a{
		text-decoration: none;
		font-weight: 600;
		color: #222;
		&:hover{
			color: #777;
		}
	}
}
.bottom-nav-bar{
	padding: 10px 0;
	border-top: solid 1px #222;
	// border-bottom: solid 4px #680393; 	// purple
	border-bottom: solid 4px #017a0a; 		// green
	.bottom-main-nav{
		margin-bottom: 5px;
		font-size: 20px;
		li{
			&:after{
				// right: -18px;
			}
		}
	}
	.bottom-sub-nav{
		font-size: 16px;
		li{
			&:after{
				// right: -18px;
			}
		}
	}
}
.debug{
  border-top: solid 1px #ccc;
  p{
    margin-bottom: 5px;  
    line-height: 14px;
  }
  .debug-data{
   background: #333; 
   color: #ccc;
   line-height: 18px;
   margin-bottom: 0;
   padding-top: 2px;
   padding-bottom: 2px;
  }
}
</style>