<template>
	<div class="page page-attack">
		<Attack :contextUser="contextUser"/>
	</div>
</template>
<script>
import Attack from '../components/Attack.vue';

export default {
	name: 'page-attack',
	props: {
		
	},
	data (){
		return {
			playerId: null,
		}
	},
	created() {
    this.playerId = this.$route.params.id
  },
  computed: {
    contextUser() {

      let contextPlayer; 
      let ranks = this.$store.state.ranksData;
      let playerId = this.playerId;

      contextPlayer = ranks.filter(function(player){
        if(player.id == playerId){
          return player;
        }
      });
      return contextPlayer;
    }
  },
	components: {
		Attack
	},
	methods: {

	},
}


</script>

<style>
.page-attack{
	margin-top: 120px;
}
</style>