<template>
	<div class="attack-template">
		<div class="inner">
			<h2>Attackin' pimp</h2>
			<p>who we hittin' today boss?</p>
			<h3>{{contextUser[0].name}}</h3>
      
			<form>
				<fieldset>
					<div>
						<div class="input-group">
							<input type="radio" id="do-driveby" name="contact" value="driveby" checked>
							<label for="do-driveby">do a driveby w/ 1 thug.</label>
						</div>
						<div class="input-group">
							<input type="radio" id="do-runup" name="contact" value="runup">
							<label for="do-runup">run up on Samael's crib.</label>
						</div>
						<div class="input-group">
							<input type="radio" id="do-infest" name="contact" value="infest">
							<label for="do-infest">infest hoes with disease.</label>
						</div>
						<div class="input-group">
							<input type="radio" id="do-throw-rocks" name="contact" value="throw-rocks">
							<label for="do-throw-rocks">throw 
								<input type="number" id="crack-amount" name="crack-amount" min="1" max="60"> 
								rocks at the hoes.
							</label>
						</div>
						<div class="input-group">
							<input type="radio" id="do-throw-weed" name="contact" value="throw-weed">
							<label for="do-throw-weed">throw 
								<input type="number" id="weed-amount" name="weed-amount" min="1" max="60"> 
								grams of weed at thugs.
							</label>
						</div>
					</div>
				</fieldset>
			</form>
			<Button @btn-click="submitAttack" text="attack"/>
		</div>
	</div>
</template>


<script>
import Button from './Button';

export default {
	name: 'attack',
	props: {
		contextUser: Object
	},
	data (){
		return {

		}
	},
	components: {
		Button
	},
	methods: {
		submitAttack(e){
			e.preventDefault();
			console.log('event', e);

			console.log(this.contextUser);
		},
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
form{
	margin-top: 30px;
}
button{
	padding: 5px 15px;
	display: block;
	margin: auto;
	margin-top: 20px;
}
.attack-template{
	color: #f7f7f7;
	background: #454545;
	min-height: 200px;
	min-width: 400px;
	border: solid 1px #222;
	border: solid 1px purple;
	h2{
		color: #dfdfdf;
	}
	.inner{
		border: solid 1px #bbb;
		padding: 30px;
	}
}
fieldset{
	display: inline;
}
.input-group{
	margin-bottom: 10px;
	&:last-of-type{
		margin: 0;
	}
}
input[type="radio"]{
	margin-right: 10px;
	height: 24px;
}
</style>

