<template>
  <div class="page page-message-board">
    <MessageBoard />
  </div>
</template>

<script>
import MessageBoard from '../components/MessageBoard.vue';

export default {
  name: 'page-message-board',
  data (){
    return {
      playerId: null,
    }
  },
  components: {
    MessageBoard,
  }
}
</script>

<style>
.page-message-board{
  margin-top: 120px;
}
</style>