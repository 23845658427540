<template>
	<div class="purchase-template">
		<h2>Produce Drug</h2>
		<div class="purchase-items">
			<div class="form-group">
					<label for="model">Produce:</label>
					<input id="amount-to-produce" type="number" v-model="amount"/><br>
					<select name="production" id="production" v-model="selectedProduction">
						<!-- <option disabled value="">Please select one</option> -->
						<option value="crack">Crack</option>
						<option value="weed">Weed</option>
					</select><br>
					<!-- <span>Selected: {{ selectedModel }}</span> -->
					<Button text="produce" @click="produceDrugs"/>
			</div>
		</div>
	</div>
	<div class="wtf-u-going">
		Where the fuck u going ? 
	</div>
</template>


<script>
import Button from './Button';
// import helpers from '../js/helpers';
// import userData from '../data/userData';

export default {
	name: 'Produce',
	props: {
		
	},
	data (){
		return {
			userData: null,
			amount: 10,
			selectedProduction: 'crack',
		};
	},
	created() {
		// helpers.doMath();

		// {this.condoms} = 
		// this.userData = 

		function init() {
			console.log('init');
		}
		init();
	},
	components: {
		Button
	},
	// computed: {
	//   formatCash(cash){
	//     return cash;
	//   }
	// },
	methods: {
		produceDrugs() {
			console.log('ok, im produce drugs', this.selectedProduction, this.amount);
			let drugs;
			if (this.selectedProduction === 'crack') {
				drugs = {
					crack: this.calculateProduction()
				}
				this.$store.commit('updateProduceDrugsCrack', drugs);
			} else if (this.selectedProduction === 'weed') {
				drugs = {
					weed: this.calculateProduction()
				}
				this.$store.commit('updateProduceDrugsWeed', drugs);
			}
		},

		calculateProduction() {
			// let min = 40;
		//   let max = 80;

		//   let coefficient = Math.floor((Math.random() * max) + min) / 100;
		//   let baseCalc = (this.thug / 10000) * this.turnsSpent;
		//   this.hoeReturn = Math.floor(baseCalc * coefficient);
			return this.amount;
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wtf-u-going{
	margin-top: 2000px;
	margin-bottom: 50vh;
}
#amount-to-produce{
	min-width: 80px;
	text-align: center;
}
#production{
	min-width: 120px;
	text-align: center;
}
.produce-template{
	color: #f7f7f7;
	padding: 30px;
	background: #454545;
	min-height: 400px;
	min-width: 400px;
	border: solid 1px;
}
select{
	margin-top: 5px;
}
button{
	margin-top: 15px;
}

</style>