<template>
  <div class="page page-scout">
    <Scout :currentUser="getCurrentUser" :userId="getCurrentUserId" />
  </div>
</template>

<script>
import Scout from '../components/Scout.vue';

export default {
  name: 'page-scout',
  data (){
    return {
      currentUserId: null,
      currentUser: null
    }
  },

  computed: {
    getCurrentUser () {
      console.log('here: ', this.$store.getters.getCurrentUser);
      return this.$store.getters.getCurrentUser;
    },
    getCurrentUserId () {
      return this.$store.getters.getCurrentUserId;
    }
  },

  components: {
    Scout,
  },
  methods: {
  },
}
</script>

<style>
.page-profile{
  margin-top: 120px;
}
</style>