<template>
	<tr class="ranks-record">
		<td class="record-rank">
			{{currentRecord.id}}
		</td>
		<td class="record-is-online">
			<span class="img-restriction">
				<img src="https://place-hold.it/32x32" />
			</span>
		</td>
		<td class="record-name">
			<router-link :to="'/profile/' + currentRecord.id">{{currentRecord.name}}</router-link>
		</td>
		<td class="record-hoes">
			{{currentRecord.hoe}}
		</td>
		<td class="record-thugs">
			{{currentRecord.thug}}
		</td>
		<td class="record-rides">
			{{currentRecord.rides}}
		</td>
		<td class="record-networth">
			{{currentRecord.networth}}
			{{formatCash}}
		</td>
		<td class="record-contact">
			<router-link :to="'/message/' + currentRecord.id" class="img-restriction"><img class="ranks-icon icon-mail" src="https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fcdn.onlinewebfonts.com%2Fsvg%2Fimg_184197.png&f=1&nofb=1&ipt=54abac7c470c1357230ae7b32902678c2b1db8ed2cd00faf94247bb1a46c6984&ipo=images" /></router-link>
		</td>
		<td class="record-is-attackable">
			<router-link :to="'/attack/' + currentRecord.id" class="img-restriction"><img class="ranks-icon icon-attack" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.8lR-ibRWAIbWZIOkxAIfqAHaER%26pid%3DApi&amp;f=1&amp;ipt=6983ae7fc377d625a4a3876774b268e51d4f674f8da0cab8e3a9fb3c87f37fa0&amp;ipo=images" /></router-link>
		</td>
	</tr>
</template>



<script>
import helpers from '../js/helpers';

export default {
	name: 'RanksRecord',
	props: {
		currentRecord: Object,
	},
	computed: {
		// calculateNetworth() {
		//   return (this.currentRecord.hoe * 2000) + (props.currentRecord.thug * 500);
		// },
		formatCash() {
			// let helpers = helpers;
			
			console.log(helpers.formatCash(1000));
			return 'format some loot yo', helpers;
		}
	},
	methods: {
		doStuff() {
			return 'nothing'
		},
		gotoMessage(record) {
			console.log('messaging player', record.name);
		},
		gotoAttack(record) {
			console.log('attacking player', record.name);
			// do here
		},
		gotoProfile(record) {
			console.log('go to profile of player', record.name);
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img{
	max-width: 100%;
}
tr.ranks-record{
	// cursor: pointer;
	td{
		a{
			color: green;
			font-weight: 600;
			cursor: pointer;
			text-decoration: underline;
		}
		
	}
}
.img-restriction{
	width: 24px;
	display: inline-block;
}
.table-hover>tbody>tr:hover>*{
	background: #eee2f4;
}
</style>
