<template>
	<div class="status-bar">
		<div class="exit" @click="closePanel">X</div>
		<ul>
			<li><span class="heading">Turns</span><span class="data">{{user.turns}}</span></li>
			<li><span class="heading">Cash</span><span class="data">{{formatCash(user.cashOnHand)}}</span></li>
			<li><span class="heading">Hoes</span><span class="data">{{user.hoe}}</span></li>
			<li><span class="heading">Condoms</span><span class="data">{{formatUnit(user.condoms)}}</span></li>
			<li><span class="heading">Crack</span><span class="data">{{user.crack}}</span></li>
			<li><span class="heading">Thugs</span><span class="data">{{user.thug}}</span></li>
			<li><span class="heading">Weed</span><span class="data">{{user.weed}}</span></li>
		</ul>
		<div class="status-turns-bar">
			<div class="current-turns">you will receive <span class="bold">5</span> turns in: <span class="bold">3 minutes, 32 seconds</span></div>
			<div class="max-turns">you can hold up to 2,500 turns</div>
		</div>
	</div>
</template>



<script>

export default {
	name: 'StatusBar',
	props: ['user'],
	components: {

	},
	methods: {
		formatCash(loot) {
			loot = Math.floor(loot).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD'
			});
			return loot;
		},
		formatUnit(unit) {
			console.log('formatUnit(): ', unit);
			return unit;
		},	
		closePanel(){
			console.log('close panel');
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
ul, li{
	padding: 0;
	margin: 0;
	list-style-type: none;
}
ul{
	display: flex;
	justify-content: space-around;
	max-width: 1000px;
	margin: auto;
}
.heading, .data{
	display: block;
}
.heading{
	font-size: 20px;
	font-weight: bold;
}
.status-bar{
	color: #f7f7f7;
	padding: 4px 20px;
	background: #454545;
}
.status-turns-bar{
	font-size: 12px;
	// border-top: solid 1px #017a0a;
	// border-top: solid 1px #680393;
	border-top: solid 1px #999;
	margin-top: 4px;
	padding-top: 4px;
}
.exit{
	position: absolute;
	cursor: pointer;
}
</style>