<template>
	<div class="discipline-template">
		<h2>dissin'</h2>
		<Button text="beatem up" />
	</div>
</template>

<script>
import Button from './Button';

export default {
	name: 'Discipline',
	props: {
	},
	components: {
		Button
	},
	methods: {
	}
}

</script>
<style lang="scss">

.discipline-template{
	color: #f7f7f7;
	padding: 30px;
	margin-top: 80px; // to be removed
	background: #454545;
	min-height: 400px;
	min-width: 400px;
	border: solid 1px;
	h2{
		color: #dfdfdf;
	}
}

</style>
