<template>
  <div id="typeahead-component">
    <typeahead :options="options" v-model="selected" @update-search="getTypeaheadItems"></typeahead>
  </div>
</template>

<script>
import Typeahead from '../components/Paslode'
export default {
  name: 'app',
  components: {
    Typeahead
  },
  data() {
    return {
      selected: null,
      options: [],
      allOptions: [
        {id: 1, text: 'Apple'},
        {id: 2, text: 'Mango'},
        {id: 3, text: 'Cherry'},
        {id: 4, text: 'Orange'},
        {id: 21, text: 'Appl22e'},
        {id: 22, text: 'Mang2o'},
        {id: 23, text: 'Cher2ry'},
        {id: 24, text: 'Oran2ge'},
        {id: 41, text: 'A4pple'},
        {id: 42, text: 'Ma4ngo'},
        {id: 43, text: 'Ch4erry'},
        {id: 44, text: 'Ora4nge'}
      ],
      api: null,
    }
  },
  methods: {
    getTypeaheadItems(query) {
      console.log('get type ahead items. query: ', query);

      if(query === ''){
        this.options = [];
        return;
      }

      const exp = new RegExp(query, 'gi');

      let filteredOptions = this.allOptions.filter((option) => {
        return exp.test(option.text) || exp.test(option.id);
      });

      console.log('filtered options: ', filteredOptions);

      this.options = filteredOptions;

    },
    doNewSearch(query){
      console.log('doing test with query: ', query);
      this.getTypeaheadItems(query);
    }
  },
  
}
</script>

<style>
.testapi{
  cursor: pointer;
}
</style>

<!-- 
searchUsers: debounce(function() {
  fetch(`https://api.github.com/search/users?q=${this.query}`)
    .then(response => {
      return response.json();
    })
    .then(data => {
      this.users = data.items;
    })
}, 500)

 -->