<template>
  <div class="page page-profile">
    <Profile :contextUser="contextUser" />
  </div>
</template>

<script>
import Profile from '../components/Profile.vue';

export default {
  name: 'page-profile',
  data (){
    return {
      playerId: null,
    }
  },
  created() {
    this.playerId = this.$route.params.id
  },
  computed: {
    contextUser() {

      let contextPlayer; 
      let ranks = this.$store.state.ranksData;
      let playerId = this.playerId;

      contextPlayer = ranks.filter(function(player){
        if(player.id == playerId){
          return player;
        }
      });
      return contextPlayer;
    }
    
  },
  components: {
    Profile,
  },
  methods: {
  },
}
</script>

<style>
.page-profile{
  margin-top: 120px;
}
</style>