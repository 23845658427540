<template>
  <div class="page page-scroll">
    <h2>Scroll</h2>

    <div class="card-container">

      <div class="card" v-for="(card,index) in cards" :key="index">
        {{card.text}}
      </div>

    </div>
    
  </div>
</template>
<script>

export default {
  name: 'page-scroll',
  data (){
    return {
      cards: [
        {
          id: 1,
          text: 'craftsman: 1st card',
        },
        {
          id: 2,
          text: 'swingline',
        },
        {
          id: 3,
          text: 'hacksaw',
        },
        {
          id: 4,
          text: 'hacksaw 2',
        },
        {
          id: 5,
          text: 'Hammer XL',
        },
        {
          id: 6,
          text: 'Regular Stapler',
        },
        {
          id: 7,
          text: 'Saw 40+',
        },
        {
          id: 8,
          text: 'ocho loco en el agujero',
        },
        {
          id: 9,
          text: 'card 9',
        },
        {
          id: 10,
          text: 'card 10',
        },
      ]
    }
  },

  created () {
    // window.addEventListener('scroll', this.handleScroll);
    console.log('created');

    // let cards = document.querySelectorAll('.card');

    // // const observer = new IntersectionObserver(entries => {
    // //   console.log(entries);
    // // })

    // console.log('cards: ', cards);
    // // observer.observe(cards[0])

  },
  mounted () {
    console.log('mounted');
    let cards = document.querySelectorAll('.card');
    let headerHt = '-' + $('header').height() + 'px';
    let footerHt = '-' + $('footer').height() + 'px';
    let margin = `${headerHt} 0px ${footerHt} 0px`;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          entry.target.classList.toggle('show', entry.isIntersecting)
        })
      },
      {
        threshold: 1,
        rootMargin: margin
      }
    )

    cards.forEach(card => {
      observer.observe(card)
    })

  },
  unmounted () {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
  },
  components: {
  },
  methods: {
    handleScroll(e){
      console.log('handle scroll', e);
    }
  },
}

</script>

<style>
header, footer{
  /*display: none !important;*/
}

.card-container{
  max-width: 500px;
  margin: auto;
}
.card{
  padding: 30px;
  min-height: 100px;
  border: solid 1px black;
  border-radius: 5px;
  margin-bottom: 30px;
  transform: translateX(100px);
  transition: 150ms;
  opacity: 0;
}
.card.show{
  opacity: 1;
  transform: translateX(0);
}
.page-scroll{
  margin-top: 120px;
  margin-bottom: 500px;
}

</style>