<template>
  <div class="scout-template">
    <div class="current-user">
      <h2>Current User : id: {{userId}}</h2>
      <h3>User</h3> {{currentUser}}<br>
      id: {{currentUser[0].id}}<br>
      name: {{currentUser[0].name}}<br>
      hoe: {{currentUser[0].hoe}}<br>
      thug: {{currentUser[0].thug}}<br>
      cash: {{currentUser[0].cashOnHand}}<br>
      turns: {{currentUser[0].turns}}
    </div>
    <h2>scoutin' fo ho fo sho</h2>
    <div class="scout-form-wrappr">
      <p>thugs: {{currentUser[0].thug}}</p>
      <form class="scout-form">
        <label for="scout-amount">
          <!-- Use <input type="number" id="scout-amount" name="scout-amount"> turns scoutin' fo ho -->
          Use <input type="number" id="scout-amount" name="scout-amount" min="1" max="60" v-model="scoutAmount"> turns scoutin' fo ho
        </label>
        <Button @btn-click="submitScout" text="scout" />
      </form>
      <div class="output">
        <p>using <span class="bold">{{turnsSpent}}</span> turns, you fount <span class="bold">{{hoeReturn}}</span> hoe and fount <span class="bold">{{thugReturn}}</span> thug.</p>
        <p>your hoes returned with <span class="bold">{{cashReturn}}</span> in cash.</p>
      </div>
    </div>
  </div>
</template>


<script>
import Button from './Button';

export default {
  name: 'Scout',
  props: {
    currentUser: Object,
    userId: Number
  },
  data (){
    return {
      saving: false,
      scoutMessage: '',
      scoutAmount: 10,
      turnsSpent: 0,
      turnsToSpend: 0,
      thug: 23457,
      // thug: 128,
      // thug: 5000,
      hoeReturn: 0,
      thugReturn: 0,
      cashReturn: 0,
      user: this.currentUser,
      
    }
  },
  components: {
    Button
  },
  computed: {
    getThugCount(){
      let thug = 1000;
      return thug;
    }
    // currentUser() {
    //   let d = this.$store.state.currentUser;
    //   console.log("currentUser: ", d);
    //   return this.$store.state.currentUser
    // },
    // userData() {
    //   let d = this.$store.state.userData;
    //   console.log("userData: ", d);
    //   return this.$store.state.userData
    // },
  },
  methods: {
    submitScout(e){
      e.preventDefault();
      // this.turnsToSpend = $('#scout-amount').val();  // look to bind this value to the input ... todo list  // check
      if (this.scoutAmount > 60) {
        return;  // send to 'stop breakin shit screeen' ... maximum turns breached
      }
      this.turnsToSpend = this.scoutAmount;
      if (this.turnsToSpend > this.currentUser[0].turns) {
        return;  // send to 'youre outta turns sucka screeen'
      }
      this.turnsSpent = this.turnsToSpend;
      this.scoutAlgorithm();
      this.scoutMessage = `spent ${this.turnsSpent} scoutin fo ho`;
      this.updateScout();
    },
    logger(){
      let msg = this.userId;
      console.log('loggerMsg: ', msg);

    },
    scoutAlgorithm() { // remove ... this is just for testing: implimenting logger()
      this.hoePickupAlgo();
      this.thugPickupAlgo();
      this.cashPickupAlgo();
      this.logger();
    },
    hoePickupAlgo() {
      let min = 40;
      let max = 80;

      let coefficient = Math.floor((Math.random() * max) + min) / 100;
      let baseCalc = (this.thug / 10000) * this.turnsSpent;
      this.hoeReturn = Math.floor(baseCalc * coefficient);
    },
    thugPickupAlgo() {
      let min = 40;
      let max = 80;

      let coefficient = Math.floor((Math.random() * max) + min) / 100;
      let baseCalc = (this.thug / 20000) * this.turnsSpent;
      this.thugReturn = Math.floor(baseCalc * coefficient);
    },
    cashPickupAlgo() {
      let min = 40;
      let max = 80;

      let coefficient = Math.floor((Math.random() * max) + min) / 100;
      let baseCalc = (this.thug / 2) * this.turnsSpent;

      this.cashReturn = Math.floor(baseCalc * coefficient);
      // this.cashReturn = Math.floor(baseCalc * coefficient).toLocaleString('en-US', {
      //   style: 'currency',
      //   currency: 'USD'
      // });
    },
    calcUserId(id) {
      return id - 1;
    },
    updateScout() {
      console.log('currentUser: ', this.user);
      this.saving = true;
      let data = {
        id: this.calcUserId(this.userId),
        hoe: this.hoeReturn,
        thug: this.thugReturn,
        cashOnHand: this.cashReturn,
        turns: -this.turnsSpent
      };
      console.log('data: ', data);
      this.$store.commit('updateUsersData', data);
      this.$store.commit('updateRanksData', data);
      // this.$store.commit('updateUsersData', data);

      this.handleTurns(this.turnsSpent);
    },

    handleTurns(turns) {
      console.log('turns', turns);

    },

    validate() {
      // before updateScoute()
      // do validation
      // test if u have enough turns
      // test ur resourses ( meds, condoms, weed, crack ) and/or (process not enough item in inventory(meds,condoms,etc)) maybe here, maybe somewher else
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button{
  padding: 5px 15px;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.scout-template{
  color: #f7f7f7;
  padding: 30px;
  background: #454545;
  min-height: 400px;
  min-width: 400px;
  border: solid 1px;
  h2{
    color: #dfdfdf;
  }
}
#scout-amount{
  width: 60px;
}
.output{
  margin-top: 30px;
  p{
    margin-bottom: 2px;
    span{
    font-size: 20px;
      font-weight: 600;
    }
    &:last-of-type{
      margin-bottom: 0;
    }
  }
}
</style>