const userData = [
  {
    id: 1,
    name: 'stackahoe',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 2,
    name: 'pimphomie-o-',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 3,
    name: 'Illmatic',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 777,
    cashOnHand: 400000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 1000,
    weed: 500,
    condoms: 100090,
    meds: 20000,
  },
  {
    id: 4,
    name: 'lindo402806',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 5,
    name: 'AssAssAssIn',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 6,
    name: 'bLasted',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 7,
    name: 'ladySlayer',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 8,
    name: 'Frank_Black',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 9,
    name: 'mixNmatch',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    hoe: 0,
    turns: 1000,
    cashOnHand: 200000,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 10,
    name: 'blue42',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    turns: 1000,
    cashOnHand: 200000,
    hoe: 0,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 11,
    name: 'nBaUsCtKy',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    turns: 1000,
    cashOnHand: 200000,
    hoe: 0,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 12,
    name: 'bak',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    turns: 1000,
    cashOnHand: 200000,
    hoe: 0,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 13,
    name: 'Chef_Boy_RPG',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    turns: 1000,
    cashOnHand: 200000,
    hoe: 0,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
  {
    id: 14,
    name: 'Paint_is_SupaWet',
    meta: {
        isOnline: true,
        currentState: 'online-pimps'
    },
    turns: 1000,
    cashOnHand: 200000,
    hoe: 0,
    thug: 0,
    rides: {
        bikes: 0,
        cutlas: 0,
        hummer: 0
    },
    guns: {
        glock: 0,
        shotgun: 0,
        ak: 0
    },
    crack: 0,
    weed: 0,
    condoms: 0,
    meds: 0,
  },
];

export default userData;