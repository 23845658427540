<template>
	<header>
		<div class="gohome"><router-link to="/">gohomeandcry</router-link>
			<br><a href="#" class="debug-trigger">hide debug</a>
		</div>
		
		<div class="top-nav-bar">
			<nav class="top-main-nav">
				<ul>
					<li><router-link to="/scout">Scout</router-link></li>
					<li><router-link to="/produce">Produce</router-link></li>
					<li><router-link to="/discipline">Discipline</router-link></li>
					<li><a href="#" class="not-working-yet">Travel</a></li>
					<li><router-link to="/purchase">Purchase</router-link></li>
					<li><router-link to="/ranks">Ranks</router-link></li>
					<li><router-link to="/special">Special</router-link></li>
				</ul>
			</nav>
			<nav class="top-sub-nav">
				<ul>
					<li><a class="not-working-yet" href="#">find pimp</a></li>
					<li><a class="not-working-yet" href="#">find crew</a></li>
					<li><a class="not-working-yet" href="#">my crew</a></li>
					<li><router-link to="/online">who is online</router-link></li>
					<li><a class="not-working-yet" href="#">crew ranks</a></li>
					<li><router-link to="/ranks">global ranks</router-link></li>
				</ul>
			</nav>
		</div>
	</header>
</template>



<script>

export default {
	name: 'Header',
	props: {
	},
	components: {
	},
	methods: {
		doStuff() {
			return 'nothing'
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gohome{
	position: absolute;
	top: 25px;
	left: 20px;
}
.not-working-yet{
  color: #b4afcd;
  pointer-events: none;
}
header{
	border-top: solid 4px #017a0a; 
	width: 100%;
	background-color: #dfdfdf;
	border-bottom: solid 2px #777;
	position: fixed;
	top: 0;
	z-index: 10;
}
ul,li{
	padding: 0;
	margin: 0;
	list-style-type: none;
}
li{
	position: relative;
	display: inline-block;
	margin-right: 30px;
	&:after{
		content: '\00BA';
		position: absolute;
		top: 6px;
		right: -18px;
	}
	&:last-of-type{
		margin-right: 0;
		&:after{
			content: '';
		}
	}
	a{
		text-decoration: none;
		font-weight: 600;
		color: #222;
		&:hover{
			color: #777;
		}
	}
}
.top-nav-bar{
	padding: 10px 0;
	border-top: solid 1px #222;
	border-bottom: solid 1px #222;
	.top-main-nav{
		margin-bottom: 5px;
		font-size: 20px;
		li{
			&:after{
				// right: -18px;
			}
		}
	}
	.top-sub-nav{
		font-size: 16px;
		li{
			&:after{
				// right: -18px;
			}
		}
	}
}
</style>