<template>
  <div class="page page-special">
    <h2>Special</h2>
    <div class="filter-bar">
      <h3>filters</h3>
      <div class="filter-elements">
        <div class="form-group">
          <label for="make">Choose a make:</label>
          <select name="make" id="make" v-model="selectedMake">
            <!-- <option disabled value="">Please select one</option> -->
            <option value="">All Makes</option>
            <option value="tool">Tool</option>
            <option value="fastiner">Fastiner</option>
            <option value="saw">Saw</option>
          </select>
          <span>Selected Make: {{ selectedMake }}</span>   
        </div>
        <div class="form-group">
          <label for="model">Choose a model:</label>
          <select name="model" id="model" v-model="selectedModel">
            <!-- <option disabled value="">Please select one</option> -->
            <option value="">All Models</option>
            <option value="wrench">Wrench</option>
            <option value="stapler">Stapler</option>
            <option value="hacksaw">Hacksaw</option>
            <option value="table saw">Tablesaw</option>
            <option value="hammer">Hammer</option>
          </select>
          <span>Selected: {{ selectedModel }}</span>
        </div>
      </div>
    </div>
    <h3 style="margin-top:20px;">The soft Data: OR</h3>
    <ul>
      <li v-for="(tool, index) in filteredProducts" :key="index">
        <strong>{{tool.name}}</strong> : {{tool.make}} ... Model: <strong>{{tool.model}}</strong>
      </li>
    </ul>
    <h3 style="margin-top:20px;">The Hard Data: both filters must be true</h3>
    <ul>
      <li v-for="(tool, index) in filteredProductsHard" :key="index">
        <strong>{{tool.name}}</strong> : {{tool.make}} ... Model: <strong>{{tool.model}}</strong>
      </li>
    </ul>
  </div>
</template>
<script>

export default {
  name: 'page-special',
  data (){
    return {
      selectedMake: '',
      selectedModel: '',
      productsArray: [
        {
          id: 1,
          name: 'craftsman',
          make: 'tool',
          model: 'wrench',
        },
        {
          id: 2,
          name: 'swingline',
          make: 'fastiner',
          model: 'stapler',
        },
        {
          id: 3,
          name: 'hacksaw',
          make: 'saw',
          model: 'hacksaw',
        },
        {
          id: 4,
          name: 'hacksaw 2',
          make: 'saw',
          model: 'hacksaw',
        },
        {
          id: 5,
          name: 'Hammer XL',
          make: 'tool',
          model: 'hammer',
        },
        {
          id: 6,
          name: 'Regular Stapler',
          make: 'fastiner',
          model: 'stapler',
        },
        {
          id: 7,
          name: 'Saw 40+',
          make: 'saw',
          model: 'table saw',
        },
        {
          id: 8,
          name: 'ocho loco en el agujero',
          make: 'crazy Eights',
          model: 'stapler',
        },
      ]
    }
  },

  computed: {

    filteredProductsHard(){  // Hard Data: all filters must be true

      let sMake = this.selectedMake;
      let sModel = this.selectedModel;

      let check = this.productsArray.filter((item) => {
        let checkMake;
        if(sMake == ''){
          checkMake = this.productsArray;
        }else{
          checkMake = (item.make == sMake);  
        }
        return checkMake;
      }).filter((item) => {
        let checkModel;
        if(sModel == ''){
          checkModel = this.productsArray;
        }else{
          checkModel = (item.model == sModel);  
        }
        return checkModel;
      });
      return check;
    },

    filteredProducts() {  // Soft Data: only 1 filter needs to be true : using methods for each value

      // gather appropriate data 
      let make = this.filteredByMake();
      let model = this.filteredByModel();

      let newArray = [...make, ...model]; // add the data togehter

      let removeDuplicates = [...new Map(newArray.map(v => [v.id, v])).values()]  // remove duplicates

      let sorted = removeDuplicates.sort((a, b) => a.id - b.id);  // sort by id

      return sorted;
    }
  },
  components: {
  },
  methods: {
    filteredByMake() {
      // this.store.dispatch('doAction');
      this.$store.dispatch('doAction');
      const query = this.selectedMake.toLowerCase();
      if(this.selectedMake == ""){
        // console.log(this.productsArray);
        return this.productsArray;
      }
      return this.productsArray.filter((product) => {
        return Object.values(product).some((word) => 
          String(word).toLowerCase().includes(query)
        );
      });
    },
    filteredByModel() {
      const query = this.selectedModel.toLowerCase();
      if(this.selectedModel == ""){
        // console.log(this.productsArray);
        return this.productsArray;
      }
      return this.productsArray.filter((product) => {
        return Object.values(product).some((word) => 
          String(word).toLowerCase().includes(query)
        );
      });
    },
  },
}

</script>

<style>
.page-special{
  margin-top: 120px;
  margin-bottom: 500px;
}
.filter-bar{
  background: #ccc;
  min-height: 20px;
}
</style>