<template>
	<div class="page page-home">
		<h2>Home</h2>
	</div>
</template>
<script>

export default {
	name: 'page-home',
	data (){
		return {
		}
	},
	components: {
	},
	methods: {
	},
}


</script>

<style>
.page-home{
	margin-top: 120px;
}
</style>