
const helpers = {

	doMath() {
		console.log('doing math: ');
	},

	formatCash(loot) {
		loot = Math.floor(loot).toLocaleString('en-US', {
			style: 'currency',
			currency: 'USD'
		});
		return loot;
	},

	formatUnit() {
			console.log('formatUnit()');
	}
}

export default helpers;