<template>
  <div class="profile-template template">
    <Button text="clickoff" />
    <div>
      <h2>Profile: {{contextUser[0].name}}</h2>
      <p>hoe: {{contextUser[0].hoe}}</p>
      <p>thug: {{contextUser[0].thug}}</p>
    </div>
  </div>
</template>


<script>
import Button from './Button';

export default {
  name: 'Profile',
  props: {
    contextUser: Object
  },
  data (){
    return {
      // userData
      // currentUser: this.userData[0]
    }
  },
  components: {
    Button
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button{
  padding: 10px 30px;
}
.profile-template{
  color: #f7f7f7;
  padding: 30px;
  background: #454545;
  min-height: 400px;
  min-width: 400px;
  border: solid 1px;
  h2{
    color: #dfdfdf;
  }
}

</style>