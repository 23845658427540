<template>
  <div class="message-template template">
    <div>
      <h2>Message: {{contextUser[0].name}}</h2>
      <!-- <h3>{{contextUser[0].name}}</h3> -->
      <form class="submit-message">
        <textarea name="comment" v-model="msg" placeholder="Got somethin to say pimp? ..."></textarea>
        <Button @btn-click="sendMessage" text="send message" />
      </form>
      <div class="message-list">
        <ul>
          <li v-for="msg in getUserMailbox.slice().reverse()" :key="msg.name" class="msg" :class="msg.sent">
            <div class="msg-heading">
              <span v-if="msg.sent == 'sent'">
                <span class="left">
                  <img class="crew" src="http://placehold.it/300x300" />
                  <router-link :to="'/profile/' + msg.toPlayerId">{{msg.toPlayer}}</router-link> . . . to
                </span>
                 {{msg.timestamp}}
              </span>
              <span v-if="msg.sent != 'sent'">
                <span class="left">
                  <img class="crew" src="http://placehold.it/300x300" />
                  <router-link :to="'/profile/' + msg.fromPlayerId">{{msg.fromPlayer}}</router-link> . . . from
                </span>
                {{msg.timestamp}}
              </span>
            </div>
            <div class="msg-body">
              <p>{{msg.message}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
import Button from './Button';
import moment from 'moment';

export default {
  name: 'Message',
  props: {
    contextUser: Object
  },
  data (){
    return {
      msg: null,
      sent: null
    }
  },
  components: {
    Button
  },
  created() {
  },
  computed: {
    getUserMailbox () {
      return this.$store.getters.getUserMessageBox;
    },
    getCurrentUser() {
      return this.$store.state.userData[this.$store.state.currentUserId-1];
    }
  },
  methods: {
    sendMessage(e){
      e.preventDefault();
      let contextUser = this.contextUser;


      // ___________________
      // ___________________ delete later: just used for styling sent vs received message
      // ___________________ keep styling but make dynamic class
      // ___________________
      let random = Math.floor(Math.random()*10);
      if(random > 5){
        console.log('yes');
        this.sent = 'sent';
      }else{
        console.log('no');
        this.sent = 'received';
      }
      // this.sent = 'sent'; // remove after testing //////////////////////// ***********************       
      // ___________________

      let randomLarge = Math.floor(Math.random() * 777777777777);

      let tonow = moment().format('ddd MMM Do, h:mm:ss a');

      let msg = {
        messageId: randomLarge,
        toPlayer: contextUser.name,
        toPlayerId: contextUser.id,
        fromPlayer: this.getCurrentUser.name,
        fromPlayerId: this.getCurrentUser.id,
        message: this.msg,
        sent: this.sent,
        timestamp: tonow
      };

      console.log('msg: ', msg);


      if(this.msg !== '' && this.msg !== null){
        this.$store.commit('addMessage', msg);

        // clear msg field
        // this.msg = '';  // comment out for testing
      }else{
        console.log('go fish');
      }

      // this.$store.getters.getUserMessageBox;

    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
button{
  padding: 10px 30px;
}
textarea{
  padding: 10px;
  min-height: 200px;
  width: 80%;
  margin: auto;
  display: block;
  margin-top: 50px;
  margin-bottom: 20px;
  background: #333;
  color: #ccc;
}
.message-template{
  color: #f7f7f7;
  padding: 30px;
  background: #454545;
  min-height: 400px;
  min-width: 400px;
  border: solid 1px;
  h2{
    color: #dfdfdf;
  }
}
.crew{
  max-width: 24px;
  padding: 2px;
}
.message-list{
  margin-top: 30px;
  ul, li{
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li{
    padding: 15px;
    background: #134c20;
    // background: #155c25;
    border-bottom: solid 1px #222;
    border-top: solid 1px rgba(255, 255, 255, 0.15);
    margin-bottom: 6px;
    &.received{
      // background: #134c20;
      background: #4d1164;
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    p{
      margin-bottom: 5px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  a{
    color: #ddd;  
  }
  .msg{
    position: relative;
    padding-top: 30px;
    .msg-heading{
      position: absolute;
      right: 25px;
      top: 0px;
      font-size: 10px;
      letter-spacing: 1px;
      width: calc(100% - 40px);
      text-align: right;
      line-height: 30px;
      .left{
        position: absolute;
        left: 0;
        .crew{
          margin-right: 10px;
        }
      }
      a{
        font-size: 14px;
        margin-right: 30px;
        text-decoration: none;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    .msg-body{
      padding: 10px 10px;
      margin-top: 0px;
      background-color:  rgba(255, 255, 255, 0.05);
      text-align: left;
      // min-height: 70px;
    }
  }
}

</style>