<template>
	<div class="page page-produce">
		<Produce />
		<!-- produce -->
	</div>
</template>

<script>
import Produce from '../components/Produce.vue';

export default {
	name: 'page-produce',
	data (){
		return {
		}
	},
	components: {
		Produce
	},
	methods: {
	},
}
</script>

<style>
.page-produce{
	margin-top: 120px;
}
</style>