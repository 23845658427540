<template>
	<div class="page page-purchase">
		<Purchase />
	</div>
</template>

<script>
import Purchase from '../components/Purchase.vue';

export default {
	name: 'page-purchase',
	data (){
		return {
		}
	},
	components: {
		Purchase
	},
	methods: {
	},
}
</script>

<style>
.page-purchase{
	margin-top: 120px;
}
</style>