const ranksData = [  // Public Data
  {
    id: 1,
    name: 'stackahoe',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false  // based of the current user vs all other players in the ranks
  },
  {
    id: 2,
    name: 'pimphomie-o-',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 3,
    name: 'Illmatic',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 4,
    name: 'lindo402806',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 5,
    name: 'AssAssAssIn',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 6,
    name: 'bLasted',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 7,
    name: 'ladySlayer',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 8,
    name: 'Frank_Black',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 9,
    name: 'mixNmatch',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 10,
    name: 'blue42',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 11,
    name: 'nBaUsCtKy',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 12,
    name: 'bak',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 13,
    name: 'Chef_Boy_RPG',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
  {
    id: 14,
    name: 'Paint_is_SupaWet',
    hoe: 100,
    thug: 100,
    rides: 100,
    networth: 1,
    isOnline: false,
    isAttackable: false
  },
];

export default ranksData;