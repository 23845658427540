<template>
  <div class="purchase-template">
    <h2>Swap Meet</h2>
    <div class="purchase-items">

      <div class="col-1">
        <div class="purchase-group group">
          <h4 class="heading">drug mart</h4>
          <div class="records-list-wrapper wrapper-drug-mart">
            <ul class="records-list-headings"> 
              <li class="qty record-heading">
                Qty
              </li>
              <li class="item record-heading">
                Item
              </li>
              <li class="tobuy record-heading">
                Buy
              </li>
              <li class="tosell record-heading">
                Sell
              </li>
              <li class="own record-heading">
                Own
              </li>
            </ul> 
            <div class="records-list">
              <ul>
                <li class="record" :key="item.id" v-for="item in tableLabels.drugMart">
                  <span class="qty form-group">
                    <label :for="item.item">{{item.item}}</label>
                    <input type="text" :id="item.item" :name="item.item" minlength="0" maxlength="12" size="14" placeholder="0">
                  </span>
                  <span class="item">
                    {{item.item}}
                  </span>
                  <span class="buy">
                    {{item.buy}}
                  </span>
                  <span class="sell">
                    {{item.sell}}
                  </span>
                  <span class="own">
                    {{item.own}}
                  </span>
                </li>
              </ul>
              <Button text="purchase" @click="purchaseDrugs"/>
            </div>
          </div>
        </div>

        <div class="purchase-group group">
          <h4 class="heading">whips R us</h4>
          <div class="records-list-wrapper wrapper-rides">
            <ul class="records-list-headings"> 
              <li class="qty record-heading">
                Qty
              </li>
              <li class="item record-heading">
                Item
              </li>
              <li class="tobuy record-heading">
                Buy
              </li>
              <li class="tosell record-heading">
                Sell
              </li>
              <li class="own record-heading">
                Own
              </li>
            </ul> 
            <div class="records-list">
              <ul>
                <li class="record" :key="item.id" v-for="item in tableLabels.rides">
                  <span class="rides form-group">
                    <label :for="item.item">{{item.item}}</label>
                    <input type="text" :id="item.item" :name="item.item" minlength="0" maxlength="12" size="14" placeholder="0">
                  </span>
                  <span class="item">
                    {{item.item}}
                  </span>
                  <span class="buy">
                    {{item.buy}}
                  </span>
                  <span class="sell">
                    {{item.sell}}
                  </span>
                  <span class="own">
                    <!-- {{item.own}} -->
                    {{bike}} {{cutlas}} {{hummer}}
                  </span>
                </li>
              </ul>
              <Button text="purchase" @click="purchaseRides"/>
            </div>
          </div>
        </div>

      </div>
      <div class="col-2">
        <div class="purchase-group group">
          <h4 class="heading">Guns n Thugs</h4>
          <div class="records-list-wrapper wrapper-guns">
            <ul class="records-list-headings"> 
              <li class="qty record-heading">
                Qty
              </li>
              <li class="item record-heading">
                Item
              </li>
              <li class="tobuy record-heading">
                Buy
              </li>
              <li class="tosell record-heading">
                Sell
              </li>
              <li class="own record-heading">
                Own
              </li>
            </ul> 
            <div class="records-list">
              <ul>
                <li class="record" :key="item.id" v-for="item in tableLabels.guns">
                  <span class="rides form-group">
                    <label :for="item.item">{{item.item}}</label>
                    <input type="text" :id="item.item" :name="item.item" minlength="0" maxlength="12" size="14" placeholder="0">
                  </span>
                  <span class="item">
                    {{item.item}}
                  </span>
                  <span class="buy">
                    {{item.buy}}
                  </span>
                  <span class="sell">
                    {{item.sell}}
                  </span>
                  <span class="own">
                    {{item.own}}
                  </span>
                </li>
              </ul>
              <Button text="purchase" @click="purchaseGuns" />
            </div>
          </div>
          <p>...</p>
        </div>
        <div class="happiness">
          <div class="loot">
             Cash: $434535
          </div>
          <div class="hoe-happy">
             Hoe happiness: 99% all day
          </div>
          <div class="thug-happy">
            Thug happiness: 100%
            <!-- {{formatCash(1000)}} -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wtf-u-going">
    Where the fuck u going ? 
  </div>
</template>


<script>
import Button from './Button';
import helpers from '../js/helpers';
import userData from '../data/userData';

export default {
  name: 'Purchase',
  props: {
    
  },
  data (){
    return {
      userData: userData,
      condoms: 0,
      meds: null,
      weed: null,
      rock: null,
      thug: null,
      glock: null,
      shotty: null,
      ak: null,
      bike: 10,
      cutlas: null,
      hummer: null,
      tableLabels: {
        drugMart: [
          {
            qty: 0,
            item: 'rubbers',
            buy: 1,
            sell: null,
            // own: 2000000      /// neds to be dynamic
            own: this.condoms
          },
          {
            qty: 0,
            item: 'penicillin',
            buy: 20,
            sell: null,
            own: 100000           
          },
          {
            qty: 0,
            item: 'chronic',
            buy: 5,
            sell: null,
            own: 800000           
          },
          {
            qty: 0,
            item: 'crack',
            buy: 10,
            sell: null,
            own: 410000           
          },
        ],
        rides: [
          {
            qty: 0,
            item: 'bike',
            buy: 500,
            sell: 350,
            own: 100
          },
          {
            qty: 0,
            item: 'cutlas',
            buy: 2000,
            sell: 1500,
            own: 1
          },
          {
            qty: 0,
            item: 'hummer',
            buy: 3000,
            sell: 2500,
            own: 10
          }
        ],
        guns: [
          {
            qty: 0,
            item: 'thug',
            buy: 1000,
            sell: null,
            own: 8799873482
          },
          {
            qty: 0,
            item: 'glock',
            buy: 500,
            sell: 350,
            own: 699873112
          },
          {
            qty: 0,
            item: 'shotgun',
            buy: 2000,
            sell: 1500,
            own: 0
          },
          {
            qty: 0,
            item: 'ak',
            buy: 5000,
            sell: 3750,
            own: 8767654
          }
        ]
      }
    };
  },
  created() {
    // helpers.doMath();

    // {this.condoms} = 
    // this.userData = 

    function init() {
      console.log('init');
    }
    init();
  },
  components: {
    Button
  },
  // computed: {
  //   formatCash(cash){
  //     return cash;
  //   }
  // },
  methods: {
    purchaseDrugs(){
      console.log('ok, im purchase drugs');

      helpers.doMath(); // from helpers
      // get data
      let condoms = $('#rubbers').val();    // all of this should be changed to v-models instead of using jquery
      let meds = $('#penicillin').val();
      let weed = $('#chronic').val();
      let crack = $('#crack').val();
      console.log(condoms, meds, weed, crack);

      console.log('type: ', typeof(condoms));

      if(condoms === '' || condoms === null){
        condoms = 0;
      }
      if(meds === '' || meds === null){
        meds = 0;
      }
      if(weed === '' || weed === null){
        weed = 0;
      }
      if(crack === '' || crack === null){
        crack = 0;
      }

      let drugObject = {
        condoms: parseInt(condoms),
        meds: parseInt(meds),
        weed: parseInt(weed),
        crack: parseInt(crack),
        cashOnHand: this.userData[0].cashOnHand
      };

      // submit data
      this.$store.commit('updatePurchaseDrugs', drugObject);

    },
    purchaseRides(){
      console.log('ok, im purchase rides');
      console.log('bikes: ', this.bike);
      // get data
      let bikes = $('#bike').val();           // all of this should be changed to v-models instead of using jquery
      let cutlas = $('#cutlas').val();
      let hummer = $('#hummer').val();

      if(bikes === '' || bikes === null){
        bikes = 0;
      }
      if(cutlas === '' || cutlas === null){
        cutlas = 0;
      }
      if(hummer === '' || hummer === null){
        hummer = 0;
      }

      let ridesObject = {
        bikes: parseInt(bikes),
        cutlas: parseInt(cutlas),
        hummer: parseInt(hummer),
      };

      // submit data
      this.$store.commit('updatePurchaseRides', ridesObject);

      
    },
    purchaseGuns(){
      console.log('ok, im purchase guns');
      // get data
      let thug = $('#thug').val();            // all of this should be changed to v-models instead of using jquery
      let glock = $('#glock').val();
      let shotgun = $('#shotgun').val();
      let ak = $('#ak').val();

      if(thug === '' || thug === null){
        thug = 0;
      }
      if(glock === '' || glock === null){
        glock = 0;
      }
      if(shotgun === '' || shotgun === null){
        shotgun = 0;
      }
      if(ak === '' || ak === null){
        ak = 0;
      }

      let gunsObject = {
        thug: parseInt(thug),
        glock: parseInt(glock),
        shotgun: parseInt(shotgun),
        ak: parseInt(ak)
      };

      // submit data
      this.$store.commit('updatePurchaseGuns', gunsObject);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wtf-u-going{
  margin-top: 2000px;
  margin-bottom: 50vh;
}
.col-1{
  // background: #ccc;
  margin-right: 20px;
}
.col-2{
  // background: #ddd;
  margin-left: 20px;
}
label{
  display: none;
}
input{
  // max-width: 81px;
  font-size: 14px;
  text-align: center;
}
button{
  margin-top: 15px;
}
ul, li{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.group{
  margin-bottom: 50px;
  &:last-of-type{
    margin-bottom: 0;
  }
}
.records-list-headings{
  display: flex;
  justify-content: space-between;
  background-color: #242424;
  padding-bottom: 4px;
  li{
    font-weight: 600;
    width: 100%;
    text-align: center;
  }
}
.records-list{
  // display: flex;
  // justify-content: space-between;
  background-color: #333;
  text-align: center;
  padding: 10px;
  ul{
    border-bottom: solid 2px #3b543b;
    border-bottom: solid 2px #4b934b;
  }
  li{
    $space: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: $space;
    border-bottom: solid 1px #3b543b;
    padding-bottom: $space;
    &:last-of-type{
      border: none;
      margin-bottom: 0;
    }
    span{
      width: 100%;
      text-align: center;
    }
  }
  .record-qty, .record-item{
    // width: 20%;
  }
}
.col-1, .col-2{
  // float: left;
}
.purchase-items{
  display: flex;
}
.purchase-template{
  color: #f7f7f7;
  padding: 30px;
  background: #454545;
  min-height: 400px;
  min-width: 400px;
  border: solid 1px;
  h2{
    color: #dfdfdf;
  }
  .purchase-items{
    width: 96%;
    margin: auto;
  }
  .col-1{
    width: 48%;
  }
  .col-2{
    width: 48%;
  }
}

.wrapper-guns{

}

</style>