<template>
	<div class="ranks-template">
		<h2>Chicago</h2>
		<p>Ranks will update in 10 seconds</p>
		<!-- <p>current player: {{currentUser.name}} / [ {{currentUser.id}} ]</p> -->
		<div class="table-wrapper">
			<table class="table table-bordered table-hover table-striped">
				<thead>
					<tr>
						<th>rank</th>
						<th>ison</th>
						<th>pimp</th>
						<th>hoes</th>
						<th>thugs</th>
						<th>rides</th>
						<th>worth</th>
						<th>msg</th>
						<th>atk</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="record in getRanksData" :key="record.id">
						<RanksRecord :currentRecord="record"/>
					</template>
				</tbody>
			</table>
		</div>
	</div>
	<div class="wtf-u-going">
    Where the fuck u going ? 
  </div>
</template>


<script>

import RanksRecord from './RanksRecord.vue';

export default {
	name: 'RanksCollection',
	props: {
	},
	data (){
    return {
      ranksData: null,
    }
  },
	components: {
		RanksRecord
	},
	created() {
    this.ranksData = this.$store.state.ranksData;
  },
  computed: {
    getRanksData () {
      return this.$store.getters.getRanksData;
    }
  },
	methods: {
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.wtf-u-going{
  margin-top: 2000px;
  margin-bottom: 50vh;
}
h2{
	font-size: 40px;
	font-weight: 600;
	color: #01420f;
}
p{
	color: #f6f1fd;
	margin-bottom: 0px;
	&:last-of-type{
		margin-bottom: 0;
	}
}
table{
	margin-bottom: 0;
}
.table-wrapper{
	margin-top: 20px;
	// background: #680393;
	border: solid 2px #f6f1fd;
	padding: 2px;
}
.ranks-template{
	margin-top: 100px;
	margin-bottom: 150px;
}
</style>