<template>
	<div id="main">
		<Header />
		<TestWidget />
		<div class="container">
			<router-view :key="$route.path"></router-view>
		</div>
		<!-- <RanksCollection :currentUser="currentUser" />
		<Scout :currentUser="currentUser" /> -->
		<Footer :timer="timer" />
	</div>
</template>

<script>

// import ranksData from './data/ranksData';
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import TestWidget from './components/TestWidget.vue';
// import RanksCollection from './components/RanksCollection.vue';
// import Scout from './components/Scout.vue';



export default {
	data () {
		return {
			msg: 'this message',
			timer: 0
		}
	},
	computed: {
		test() {
			return this.$store.state.test
		},
		userData() {
			return this.$store.state.userData
		},
		currentUser() {
			return this.$store.state.userData[2]
		},
		contextUser() {
			return this.$store.state.userData[5]
		}
	},
	mounted() {
		console.log('mounted');
		// setInterval(function(){
		// 	// this.timer += 1;
		// 	console.log(this.timer);
		// }, 1000);
	},
	components: {
		Header,
		Footer,
		TestWidget
		// Scout,
		// RanksCollection
		
	},
	methods: {
		calculateNetworth() {  // keep this here ... its prolly needed globally
			this.networth = (this.hoe * 2000) + (this.thug * 500);
		},
	}
}
</script>

<style lang="scss">
// $cool-blue: ##2c3e50;
body{
	background: #999;
}
.bold{
	font-weight: bold;
}
.container{
	margin-top: 120px;
	margin-bottom: 200px;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
table{
	width: 100%;
	background: white;
}
</style>
