<template>
  <div class="page page-message">
    <Message :contextUser="contextUser" />
  </div>
</template>

<script>
import Message from '../components/Message.vue';

export default {
  name: 'page-message',
  data (){
    return {
      playerId: null,
    }
  },
  created() {
    this.playerId = this.$route.params.id  // was using this to get context user ... ** maybe use in production **
  },
  computed: {
    contextUser() {

      let contextPlayer;                                      // saving this for reference. . . delete when no longer needed
      let ranks = this.$store.state.ranksData;
      let playerId = this.playerId;

      contextPlayer = ranks.filter(function(player){
        if(player.id == playerId){
          return player;
        }
      });
      return contextPlayer;
      // return this.$store.state.userData[this.$store.state.currentContextUserId-1];
    }
  },
  components: {
    Message,
  },
  methods: {
  },
}
</script>

<style>
.page-message{
  margin-top: 120px;
}
</style>