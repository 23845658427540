<template>
	<div class="not-found-template">
		404 : stop breaking shit
		<div class="path-match">
			{{ $route.params.pathMatch }}
		</div>
	</div>
</template>


<script>
/*
___________________________________________________________________
___________________________________________________________________
___________________________________________________________________
___________________________________________________________________


	if 404 w/ profile.. show 404 msg to user

	if 404 w/ NO profile No User: send outside game 404

___________________________________________________________________
___________________________________________________________________
___________________________________________________________________
___________________________________________________________________

*/

export default {
	name: 'Not Found : 404',
	props: {
	},
	data (){
		return {
		}
	},
	components: {

	},
	methods: {

	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.not-found-template{
	margin: 120px 50px 50px 50px;
	color: #f7f7f7;
	padding: 30px;
	background: #454545;
	min-height: 400px;
	min-width: 400px;
	border: solid 1px;
	h2{
		color: #dfdfdf;
	}
}

</style>