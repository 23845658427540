<template>
  <div>
    typeahead custom
    <typeahead :options="options" value="value" v-model="selected"></typeahead>
    <br>
    |{{selected}}|
    <br>
  </div>
</template>

<script>

  import Typeahead from '../components/Typeahead';

  export default {
  data(){
    return {

      selected: null,

      options: [
        {
          id: 1,
          text: 'Tool abc',
          Type: 'accessory',
          Url: '/paslode'
        },
        {
          id: 2,
          text: 'Tool defg',
          Type: 'accessory',
          Url: '/paslode'
        },
        {
          id: 3,
          text: 'Tool hijk',
          Type: 'accessory',
          Url: '/paslode'
        },
        {
          id: 4,
          text: 'Tool lmnop',
          Type: 'accessory',
          Url: '/paslode'
        },
      ]
    }
  },

  components: {
    Typeahead
  },


  methods: {
    // lookupUser: debounce(function(){
    //   // in practice this action should be debounced
    //   fetch(`https://api.github.com/search/users?q=${this.query}`)
    //     .then(response => {
    //       return response.json();
    //     })
    //     .then(data => {
    //       this.users = data.items;
    //     })
    // }, 500)
    lookupUser(searchParam) {
      console.log('search param: ', searchParam);
      return this.options;
    }
  }  
}
</script>